/*COLORS*/
/*COLORS*/
/*COLORS*/
/*COLORS*/
.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}

.wrapper .sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  display: block;
  z-index: 4;
  color: #ffffff;
  font-weight: 200;
  background: #0CA8DC;
  background-attachment: fixed;
  -webkit-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.sidebar .nav {
  margin-top: 51px;
}

.wrapper .sidebar .nav li a span {
  transition: all 1s ease-in;
  padding-left: 15px;
}

.wrapper.min .sidebar .nav li a span {
  opacity: 0;
  position: absolute;
  display: none;
}

.sidebar .nav.nav-pills li > a {
  margin: 5px 15px 0;
  border-radius: 4px;
  color: #ffffff;
  opacity: .86;
}

.sidebar .nav.nav-pills li > a > i {
  min-width: 14px;
  text-align: center;
}

.sidebar .nav.nav-pills li.active > a {
  color: #ffffff;
  opacity: 1;
  background: rgba(255, 255, 255, 0.13);
}

.sidebar .nav.nav-pills li:hover > a,
.sidebar .nav.nav-pills li.active:hover > a {
  background: rgba(255, 255, 255, 0.13);
  opacity: 1;
  color: #ffffff;
}

.sidebar .nav.nav-pills > li > a:hover,
.sidebar .nav.nav-pills > li > a:focus {
  background: rgba(255, 255, 255, 0.13);
}

.startend-footer {
  background-color: #ffffff;
  border-color: transparent;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  position: fixed;
  right: 0;
  bottom: 0;
  width: calc(100% - 260px);
  z-index: 3;
  transition: width 1s ease-in-out;
}

.startend-footer img {
  height: 50px;
}

.startend-footer .copyright-date {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

.startend-footer .copyright-date .beta-version {
  color: #0CA8DC;
  padding: 0 5px;
}

.wrapper .main-panel {
  background-color: #f5f5f5;
  position: relative;
  float: right;
  width: calc(100% - 260px);
  min-height: 100%;
  transition: width 1s ease-in-out;
}

.wrapper.min .sidebar {
  width: 80px;
}

.wrapper.min .main-panel {
  width: calc(100% - 80px);
}

.main-panel .navbar-default {
  background-color: #ffffff;
  border-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  position: fixed;
  right: 0;
  width: calc(100% - 260px);
  z-index: 3;
  transition: width 1s ease-in-out;
}

.wrapper .navbar-default .toggle-menu {
  background-color: #0CA8DC;
  border-radius: 4px;
  color: #ffffff;
  padding: 7px;
  height: 32px;
  margin-top: 9px;
  left: 30px;
}

.wrapper.min .main-panel .navbar-default,
.wrapper.min .startend-footer {
  width: calc(100% - 80px);
}

.wrapper.min .sidebar .nav li a span {
  display: none;
}

.wrapper.min .sidebar .nav li a {
  text-align: center;
}

.main-panel > .content {
  margin-top: 51px;
  padding: 30px 15px 50px 15px;
  min-height: calc(100vh - 136px);
}

.card {
  border-radius: 4px;
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.9);
  margin-bottom: 20px;
}

.card .card-title {
  display: flex;
  background: #0CA8DC;
  background-attachment: fixed;
  border-bottom: 1px solid #cccccc;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #ffffff;
  padding: 15px;
}

.card .card-title .title {
  flex: 1;
  line-height: 35px;
}

.card .card-title span {
  padding-left: 5px;
}

.card .card-content {
  padding: 15px;
}

.card .card-footer {
  border-top: 1px solid #cccccc;
  padding: 15px 0;
  margin: 0 15px;
}

.card-content .nav-tabs {
  border-bottom: 1px solid #0CA8DC;
}

.card-content .tab-content {
  padding-top: 15px;
}

.card-content .nav-tabs a {
  border: none;
  border-radius: 0;
  outline: none;
  color: #000000;
}

.card-content .nav-tabs li.active a,
.card-content .nav-tabs li.active a:hover,
.card-content .nav-tabs li.active a:focus {
  border: none;
  outline: none;
  background-color: #0CA8DC;
  color: #ffffff;
}

.card-content .nav-tabs a:hover,
.card-content .nav-tabs a:focus {
  border: none;
  border-radius: 0;
  outline: none;
  background-color: #0CA8DC;
  color: #ffffff;
}

.card-content .top {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.card-content .top .dataTables_paginate {
  -webkit-box-flex: 1;
  flex: 1;
}

.card-content .bottom .dataTables_length {
  padding-right: 20px;
}

.card-content .bottom .dataTables_info {
  -webkit-box-flex: 1;
  flex: 1;
}

.card-content .pagination > .active > a,
.card-content .pagination > .active > a:focus,
.card-content .pagination > .active > a:hover,
.card-content .pagination > .active > span,
.card-content .pagination > .active > span:focus,
.card-content .pagination > .active > span:hover {
  background-color: #0CA8DC;
  border-color: #0CA8DC;
}

.btn-start {
  color: #fff;
  background-color: #0CA8DC;
  border-color: #0CA8DC;
}

.btn.btn-start:hover {
  color: #fff;
  background-color: #35B5E0;
  border-color: #35B5E0;
}

.content-nav .nav-tabs > li {
  float: none;
}

.content-nav .nav-tabs a {
  border-radius: 4px;
  margin: 5px 0;
}

.content-nav .nav-tabs a:hover,
.content-nav .nav-tabs a:focus {
  border-radius: 4px;
}

.content-nav .nav-tabs {
  border-bottom: 0;
}

.form-upload {
  display: inline-block;
}

.form-upload .form-image {
  display: inline-block;
  margin-bottom: 10px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  max-width: 250px;
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.form-upload .form-image img {
  max-height: 100px;
}

.form-upload .form-btn {
  vertical-align: middle;
  width: 100%;
  text-align: center;
}

.form-upload .form-btn span {
  width: auto;
}

.base-datatable thead > tr > th,
.base-datatable tbody > tr > td {
  border: none;
  vertical-align: middle;
}

.base-datatable thead > tr > th:last-child,
.base-datatable tbody > tr > td:last-child {
  width: 100px;
}

.base-datatable thead > tr {
  height: 40px;
}

.base-datatable thead > tr > th {
  vertical-align: middle;
  background: #f4f3fb;
}

.base-datatable thead > tr > th:focus {
  outline: none;
}

.base-datatable tbody > tr {
  height: 40px;
}

.base-datatable .trash {
  display: inline-block;
  position: relative;
  top: 3px;
  padding: 0 !important;
  width: 33px;
  height: 33px;
  border-radius: 60px;
}

.base-datatable .trash > i {
  color: #f44336;
  line-height: 0;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.base-datatable .trash:hover {
  background-color: #f44336;
}

.base-datatable .trash:hover > i {
  color: #ffffff;
}

.base-datatable .book {
  display: inline-block;
  position: relative;
  top: 3px;
  padding: 0 !important;
  width: 33px;
  height: 33px;
  border-radius: 60px;
}

.base-datatable .book > i {
  color: #ff8505;
  line-height: 0;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.base-datatable .book:hover,
.base-datatable tr.active .book {
  background-color: #ff8505;
}

.hotspot_title > i {
  color: #fff;
  background-color: #ff8505;
  line-height: 0;
  width: 33px;
  height: 33px;
  margin-left: 5px;
  border-radius: 60px;
  vertical-align: middle;
  position: relative;
}

.hotspot_title > i:before {
  position: absolute;
  top: 16px;
  left: 10px;
}

.base-datatable .book:hover > i,
.base-datatable tr.active .book > i {
  color: #ffffff;
}

.base-datatable tr.active td:nth-child(2) {
  font-weight: 600;
}

.base-datatable .sorting_asc:after {
  content: '\f0de';
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-left: 10px;
  vertical-align: bottom;
}

.base-datatable .sorting_desc:after {
  content: '\f0dd';
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-left: 10px;
  vertical-align: top;
}

.base-datatable .sorting:after {
  content: '\f0dc';
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-left: 10px;
}

.dropdown {
  width: 33px;
  height: 33px;
  position: relative;
  top: 3px;
  display: inline-block;
  border-radius: 60px;
}

.dropdown:hover,
.dropdown.open {
  background-color: #00bcd4;
}

.dropdown > a > i {
  color: #00bcd4;
  line-height: 0;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.dropdown:hover > a > i,
.dropdown.open > a > i {
  color: #ffffff;
}

.dropdown-menu {
  position: absolute;
  -webkit-transform: translate3d(-132px, 33px, 0px);
  transform: translate3d(-132px, 33px, 0px);
  top: 0;
  left: 0;
  right: auto;
  will-change: transform;
  padding: 0;
  margin: 0;
}

.dropdown-menu > a {
  text-decoration: none;
  color: #60C3E4;
  border-left: 5px solid #ffffff;
}

.dropdown-item {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: #60C3E4;
  text-align: inherit;
  white-space: nowrap;
  background: 0 0;
  border: 0;
  font-size: 16px;
  padding: .7rem 1.5rem;
}

.dropdown-item:hover {
  background-color: #35B5E0;
  color: #ffffff;
  border-left: 5px solid #0CA8DC;
}

.dropdown-item > i {
  padding-right: 10px;
}

.login-background {
  background-image: url(../imgs/sunset.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

.login-background .login-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #3a2050;
  opacity: 0.5;
}

.login-background .container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.message-container {
  position: fixed;
  width: 640px;
  padding: 10px 10px 10px 20px;
  z-index: 1;
  height: auto;
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}

.message-content {
  position: relative;
  width: 100%;
  padding: 15px;
  right: 15px;
  height: auto;
  border: 1px solid;
}

@media (max-width: 640px) {
  .message-container {
    width: 100%;
  }
  .message-content {
    right: 0;
  }
  .wrapper .sidebar {
    width: 80px;
  }
  .wrapper .main-panel {
    width: calc(100% - 80px);
  }
  .wrapper .main-panel .navbar-default,
  .wrapper .startend-footer {
    width: calc(100% - 80px);
  }
  .wrapper .sidebar .nav li a span {
    display: none;
  }
  .wrapper .navbar-default .toggle-menu {
    display: none;
  }
}

.login-logo,
.login-form {
  transition: all 1s ease-in;
  -webkit-transform: translateY(-40px);
  transform: translateY(-40px);
  opacity: 0;
}

.login-logo.animate,
.login-form.animate {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.login-form {
  min-width: 300px;
}

.login-form .input-group-addon {
  background-color: #ffffff;
  border: 0;
}

.login-form .input-group-addon i {
  color: #0CA8DC;
  min-width: 14px;
}

.login-form .form-control {
  border: none;
}

.login-form a,
.login-form a:hover,
.login-form a:focus {
  color: #ffffff;
}

.login-form .has-error {
  margin-bottom: 0;
}

.login-form .has-error input {
  background-color: #f2dede;
}

.login-form .help-block {
  color: #ffffff;
}

.fixed-add-menu {
  position: fixed;
  right: 15px;
  width: 14%;
  z-index: 2;
}

.fixed-add-menu .card-content .nav-tabs a:focus {
  background: none;
  color: #000000;
}

.flex-toggle {
  display: flex;
}

.flex-toggle .flex-header {
  flex: 1;
}

.component-right {
  float: right;
}

.table tr.selected .fa-square-o:before {
  content: "\f046";
}

@media (max-width: 1220px) and (min-width: 991px) {
  .add_nav .nav li {
    text-align: center;
  }
  .add_nav .nav li a span {
    display: none;
  }
}
